


















































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  FundAccountDto,
  FundDto,
  FundDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "FundAccountList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
  },
})
export default class FundAccountList extends Vue {
  typeList: any = [];
  fundList: FundDto[] = [];
  fundId?: number = 0;

  queryForm = {};

  created() {
    this.fetchEnumType();
    this.fetchFundList();
  }

  async fetchFundList() {
    await api.fund
      .getAll({
        maxResultCount: 10000,
      })
      .then((res: FundDtoPagedResultDto) => {
        this.fundList = res.items ?? [];
      });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    if (this.fundId) {
      params.fundId = this.fundId;
    }
    return api.fundAccount.getAll(params);
  }

  // 拨付明细
  jumpToFundAppropriateRecord(row: FundAccountDto) {
    this.$router.push({
      name: "fundAppropriateRecord",
      query: { fundId: row.fundID!.toString() },
    });
  }

  // 捐赠明细
  jumpToFundIncomeRecord(row: FundAccountDto) {
    this.$router.push({
      name: "fundIncomeRecord",
      query: { fundId: row.fundID!.toString() },
    });
  }
}
